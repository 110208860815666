import { useState } from 'react';

const useAdModal = ({ iconSource, mediaSource, linkUrl, title, subtitle }) => {
  const [isVisible, setIsVisible] = useState(false);
  return {
    isVisible,
    setIsVisible,
    iconSource,
    mediaSource,
    linkUrl,
    title,
    subtitle,
    openAd: () => setIsVisible(true),
  };
};

export default useAdModal;
