import * as React from 'react';
import {
  Platform,
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Modal as NativeModal,
  Animated,
  Easing,
} from 'react-native';
import styled from 'styled-components/native';
import WebModal from 'modal-enhanced-react-native-web';

import Colors from '../constants/Colors';
import TSAdBanner from './TSAdBanner';
import CancelButton from '../components/CancelButton';
import WebView from 'react-native-webview';

const Modal = Platform.select({
  web: WebModal,
  default: NativeModal,
});

const { width: screenWidth, height: screenHeight } = Dimensions.get('window');

const StyledTSAdBanner = styled(TSAdBanner)`
  width: ${screenWidth * 0.8};
  max-width: ${screenWidth >= 768 ? 380 : 300};
  height: 86;
  position: absolute;
  bottom: 0;
  left: 50%;
  elevation: 3;
`;

const ContentContainer = styled(View)`
height: ${Platform.OS === 'web' ? (screenHeight - 45) * 0.9 : (screenHeight - 45) * 0.95
  };
max-height:${screenWidth >= 768 ? 735 : Platform.OS === 'web' ? 620 : screenHeight - 60
  }
width: ${Platform.OS === 'web' ? screenWidth * 0.9 : screenWidth};
max-width: ${screenWidth >= 768 ? 420 : 300};
  background-color: #030303;
`;

const CountingdownButton = ({ isVisible, countNum, setCountNum }) => {
  const progress = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    if (Platform.OS !== 'web') {
      progress.setValue(0);
      Animated.timing(progress, {
        toValue: 1,
        duration: 7000,
        useNativeDriver: false,
        easing: Easing.linear,
      }).start();
    }
    if (isVisible) {
      let interval = setInterval(() => {
        setCountNum((prev) => {
          const next = prev - 1;
          if (next === 0) {
            clearInterval(interval);
          }
          return next;
        });
      }, 1000);
    }
  }, [isVisible]);

  return (
    <StyledButton>
      <RoundView></RoundView>
      <StyledText>{countNum}</StyledText>
    </StyledButton>
  );
};

const RoundView = styled(View)`
  position: absolute;
  width: 36;
  height: 36;
  border-radius: 18;
  border-width: 4;
  border-color: ${Colors.purple};
  background-color: transparent;
`;

const StyledText = styled(Text)`
  font-size: 14;
  font-weight: bold;
  color: ${Colors.screenBackground};
`;

const StyledButton = styled(TouchableOpacity)`
  position: absolute;
  top: 10;
  left: 16;
  width: 32;
  height: 32;
  border-radius: 16;
  background-color: white;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled(View)`
  position: relative;
  justify-content: flex-start;
  width: 100%;
`;

const TSAdFullScreen = ({
  isVisible,
  setIsVisible,
  mediaSource = 'https://player.vimeo.com/video/491023703?autoplay=1&muted=1&loop=1',
  iconSource = {
    uri:
      'https://play-lh.googleusercontent.com/tTnu7K0UQHnAKM_lNOse-ej4cmrdc1Pd9_69FvH0mzbH8Q4wIxkfiEo4fngvjCn1r4GL=s360',
  },
  linkUrl = 'https://play.google.com/store/apps/details?id=com.ins.reports.analyzer.insta.followers.tracker',
  title,
  subtitle,
}) => {
  const [countNum, setCountNum] = React.useState(0);

  React.useEffect(() => {
    isVisible && setCountNum(7);
  }, [isVisible]);

  return (
    <Modal visible={isVisible}>
      <View
        style={{
          backgroundColor: '#030303',
          // position: 'absolute',
          // top: 0,
          flex: 1,
          maxWidth: screenWidth >= 768 ? 700 : 540,
          maxHeight:
            Platform.OS === 'web' ? (screenWidth >= 768 ? 815 : 670) : null,
          alignItems: 'center',
        }}
      >
        <ButtonWrapper>
          <CancelButton
            onPress={() => setIsVisible(false)}
            style={{ marginTop: 10, marginBottom: 5 }}
          />
          {countNum !== 0 && (
            <CountingdownButton
              isVisible={isVisible}
              countNum={countNum}
              setCountNum={setCountNum}
            />
          )}
        </ButtonWrapper>
        <ContentContainer>
          {isVisible && (
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Platform.OS === 'web' ? (
                <iframe
                  src={mediaSource}
                  width={screenWidth >= 768 ? '100%' : '340'}
                  height="800"
                  frameborder="0"
                  allow="autoplay"
                ></iframe>
              ) : (
                <WebView
                  source={{
                    uri: mediaSource,
                  }}
                  style={{
                    height: screenHeight,
                    width: screenWidth,
                    backgroundColor: '#030303',
                  }}
                  originWhitelist={['*']}
                  allowsInlineMediaPlayback={true}
                  mediaPlaybackRequiresUserAction={false}
                />
              )}
            </View>
          )}
          <StyledTSAdBanner
            style={{
              transform: [
                {
                  translateX:
                    screenWidth >= 768
                      ? -Math.min((screenWidth * 0.8) / 2, 190)
                      : -Math.min((screenWidth * 0.8) / 2, 150),
                },
              ],
            }}
            iconSource={iconSource}
            linkUrl={linkUrl}
            title={title}
            subtitle={subtitle}
          />
        </ContentContainer>
      </View>
    </Modal>
  );
};

export default TSAdFullScreen;
